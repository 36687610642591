import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  computed,
  effect,
  input,
  model,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CtaComponent } from '@components/cta/cta.component';
import { IconComponent } from '@components/icon/icon.component';
import { dialogClasses } from '@constants/dialog';

export type DialogType =
  | 'dialog'
  | 'dialog-full-screen'
  | 'dialog-left'
  | 'dialog-right'
  | 'snackbar'
  | 'popup-content';

const SNACKBAR_DURATION_DEFAULT = 5000;

@Component({
  selector: 'nax-dialog',
  standalone: true,
  templateUrl: './dialog.component.html',
  imports: [
    NgClass,
    IconComponent,
    NgTemplateOutlet,
    ReactiveFormsModule,
    FormsModule,
    CtaComponent,
  ],
})
export class DialogComponent<T> implements OnDestroy {
  @HostBinding('class') class = 'nax-dialog';
  // Data that can be passed to the dialog on open and used on close
  private contextData: T | undefined;

  protected classes = computed(() => dialogClasses[this.type()] ?? '');
  protected _isOpen = signal(false);

  dialog = viewChild.required<ElementRef<HTMLDialogElement>>('appDialog');

  isAlertCloseEnabledChange = output<boolean>();

  isOpen = model(false);
  type = input<DialogType>('dialog');
  enableAlertClose = input(false);
  duration = input(SNACKBAR_DURATION_DEFAULT);
  closeButton = input(false);
  closeClickingOutside = input(true);
  icon = input<string | undefined>();
  cancelAction = input<() => void>();
  modal = input(true);

  constructor() {
    this.openChangeEffect();
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  openDialog(contextData?: T): void {
    if (contextData) this.contextData = contextData;
    if (this._isOpen()) return;

    this.showNativeElement(this.dialog().nativeElement);
    this._isOpen.set(true);
    this.isOpen.set(true);
  }

  closeDialog(callback?: (contextData: T) => void): void {
    if (!this._isOpen) return;

    this.dialog().nativeElement.close();
    this._isOpen.set(false);
    this.isOpen.set(false);
    if (callback && this.contextData) callback(this.contextData);
  }

  protected openChangeEffect(): void {
    effect(() => {
      const isOpen = this.isOpen();

      untracked(() => {
        if (!isOpen) {
          this.closeDialog();
          return;
        }

        this.openDialog();
        if (this.type() === 'snackbar') {
          setTimeout(() => {
            this.closeDialog();
          }, this.duration());
        }
      });
    });
  }

  protected clickOnClose(): void {
    this.closeDialog();
  }

  @HostListener('click', ['$event'])
  protected clickDialog(event: Event): void {
    if (this.canCloseDialog(event)) {
      this.closeDialog();
      return;
    }

    if (this.mustEmitAlertClose(event)) {
      this.isAlertCloseEnabledChange.emit(true);
    }
  }

  @HostListener('keydown.escape', ['$event'])
  protected escapeDialog(): void {
    this.cancelAction()?.();
  }

  protected isDialog(): boolean {
    return this.type().includes('dialog');
  }

  private canCloseDialog(event: Event): boolean {
    return (
      this.isDialog() &&
      this.closeClickingOutside() &&
      event.target === this.dialog().nativeElement &&
      !this.enableAlertClose()
    );
  }

  private mustEmitAlertClose(event: Event): boolean {
    return (
      event.target === this.dialog().nativeElement && this.enableAlertClose()
    );
  }

  private showNativeElement(nativeElement: HTMLDialogElement): void {
    if (!this.isDialog() || !this.modal()) {
      nativeElement.show();
    } else {
      nativeElement.showModal();
    }
  }
}
