<dialog
  #appDialog
  [ngClass]="classes() + ' dialog-container'">
  @if (_isOpen()) {
    @if (type() === "popup-content") {
      <ng-container *ngTemplateOutlet="defaultDialog" />
    } @else {
      <ng-container *ngTemplateOutlet="isDialog() ? dialog : snackbar" />
    }
  }
</dialog>

<!-- DIALOG COMPONENT -->

<ng-template #dialog>
  @if (icon(); as icon) {
    <div class="dialog-icon">
      <nax-icon [icon]="icon" />
    </div>
  }
  <div
    class="dialog-title | headline--small flex"
    [ngClass]="{ 'dialog-title--center': icon }">
    <ng-container *ngTemplateOutlet="dialogTitle" />
    <nax-cta
      appearance="text"
      scheme="neutral"
      [isIconButton]="true"
      icon="close"
      class="push-right"
      title="CLOSE"
      (click)="clickOnClose()" />
  </div>

  <div class="dialog-content | body--medium">
    <ng-container *ngTemplateOutlet="content" />
  </div>

  <div class="dialog-footer">
    <ng-container *ngTemplateOutlet="dialogFooter" />
  </div>
</ng-template>

<!-- SNACKBAR COMPONENT -->

<ng-template #snackbar>
  <div class="dialog-content">
    <ng-container *ngTemplateOutlet="content" />
  </div>

  <div class="dialog-footer">
    <ng-container *ngTemplateOutlet="dialogFooter" />
    @if (closeButton()) {
      <nax-cta
        appearance="text"
        scheme="neutral"
        [isIconButton]="true"
        icon="close"
        class="close-button"
        title="CLOSE"
        (click)="clickOnClose()" />
    }
  </div>
</ng-template>

<ng-template #defaultDialog>
  <div class="dialog-title">
    <ng-container *ngTemplateOutlet="dialogTitle" />
  </div>

  <div class="dialog-content">
    <ng-container *ngTemplateOutlet="content" />
  </div>

  <div class="dialog-footer">
    <ng-container *ngTemplateOutlet="dialogFooter" />
  </div>
</ng-template>

<!-- CONTENT TEMPLATES -->

<ng-template #content>
  <ng-content />
</ng-template>

<ng-template #dialogTitle>
  @if (closeButton()) {
    <nax-cta
      appearance="text"
      scheme="neutral"
      [isIconButton]="true"
      icon="close"
      class="close-button"
      title="CLOSE"
      (click)="clickOnClose()" />
  }
  <ng-content select="[dialog-title]" />
</ng-template>

<ng-template #dialogFooter>
  <ng-content select="[dialog-footer]" />
</ng-template>
